




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosBasicCredentials, AxiosRequestConfig } from 'axios'
import qs from 'qs'

class fileItem {
  name: string
  path: string[] //incl name
  constructor(p: string) {
    this.path = p.split('/').filter((i) => i);
    this.name = this.path[this.path.length - 1];
  }
  dlLink() {
    return qs.stringify({ path: this.getPathStr() })
  }
  getPathStr() {
    return '/' + this.path.join('/')
  }
  getParentName() {
    let i = this.path.length-2;
    if (i < 0 ) return '/'
    else return this.path[i]
  }
}
class dirItem extends fileItem {
  files: fileItem[] = []
  dirs: dirItem[] = []
  constructor(n: string) {
    super(n);
  }
}
const api = process.env.VUE_APP_APIURL + '/files.php'

@Component({
  components: {
  },
})
export default class FilesView extends Vue {

  @Prop({ type: Object, required: true, default: null }) auth!: AxiosBasicCredentials | null;

  cnt = 0;
  currentDir = new dirItem('/')

  mounted() {
    //console.log("creds:", this.auth);

    return this.getRootItems();
  }

  get api() { return api };

  async getRootItems() {
    this.currentDir = await this.getFolderItems('/')
  }
  async backUp() {
    if (this.currentDir.path.length) {
      this.currentDir.path.pop()
      const newpath = this.currentDir.path.join('/')
      this.currentDir = await this.getFolderItems(newpath)
    }
  }

  async enterSub(folder: string) {
    this.currentDir = await this.getFolderItems(folder)
  }

  async getFolderItems(folder: string) {
    const dir = new dirItem(folder)
    try {
      let opts: AxiosRequestConfig = { withCredentials: true };
      if (this.auth) opts.auth = this.auth;

      //const q = qs.stringify({ path: folder })
      const response = await this.$http.get(api + '?' + dir.dlLink(), opts)
      for (let f of response.data.items as string[]) {
        if (f[f.length - 1] != '/')
          dir.files.push(new fileItem(folder + '/' + f))
        else
          dir.dirs.push(new dirItem(folder + '/' + f));
      }
      //TODO sort by correct name
      dir.files.sort((i, j) => i.name < j.name ? -1 : 1);
      dir.dirs.sort((i, j) => i.name < j.name ? -1 : 1);
      //HTML Parser for Apache Auto generated Index
      // const parser = new DOMParser()
      // const dom = parser.parseFromString(response.data, 'text/html')

      // const xpResult = dom.evaluate('//li[position()>1]/a/@href', dom, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
      // for (let node = xpResult.iterateNext(); node != null; node = xpResult.iterateNext()) {
      //   console.debug('node', node.nodeValue)
      //   const v = (node.nodeValue || '').trim()
      //   if (v?.endsWith('/')) {
      //     dir.items.push(new dirItem(v.slice(0,v.length-2)))
      //   }
      //   else {
      //     dir.items.push(new fileItem(v))
      //   }
      // }
    } catch (error) {
      console.error('getFolderItems', error);
    }
    return dir
  }
}
