






















import { Component, Vue } from 'vue-property-decorator';
import { AxiosBasicCredentials } from 'axios'

const api = process.env.VUE_APP_APIURL + '/files.php'

@Component
class LocalsStore extends Vue {
  glbCreds: AxiosBasicCredentials | null = { username: '', password: '' };
}
const store = new LocalsStore();


@Component
export default class InternalsView extends Vue {

  // confirmed creds
  get creds() { return store.glbCreds; }

  //for form values
  user: string = ""
  password: string = "";
  //feedback
  loginErr: string = "";

  mounted() {
    (this.$refs.autofocus as HTMLInputElement).focus()
  }

  async doLogin() {
    try {
      const fd: AxiosBasicCredentials = { username: this.user, password: this.password }
      this.password = "";
      this.loginErr = "";
      store.glbCreds = null;
      const response = await this.$http.options(api, { auth: fd, withCredentials: true })
      if (response.status >= 400) {
        this.loginErr = response.statusText;
      } else {
        store.glbCreds = fd;
      }
    } catch (error) {
      console.error("Err:", error);
      this.loginErr = error?.toString();
    }
  }

  doLogout() {
    store.glbCreds = { username: '', password: '' };
  }
  get credsOK() {
    return this.creds?.username;
  }
}
