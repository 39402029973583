import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Internal from '@/views/Internal.vue'
import Files from '@/views/Files.vue'

Vue.use(VueRouter)

const routesInterna: Array<RouteConfig> = [
  {
    path: 'downloads',
    name: 'Downloads',
    component: Files,
    props: true,
  },]

const routes: Array<RouteConfig> = [
  { path: '*', redirect: '/home' },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About
  },
  {
    path: '/vip',
    name: 'Interna',
    component: Internal,
    children: routesInterna,
  },
]

const router = new VueRouter({
  routes
})

export default router
